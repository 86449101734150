import axios from 'axios';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth, webAddress } from '../../context/AuthContext';

export default function SkapaAnvandare() {
    const { isAdmin } = useAuth();
    const navigate = useNavigate();
    const [newUsername, setNewUsername] = useState('')
    const [newPassword, setNewPassword] = useState('')
    const [newName, setNewName] = useState('')
    const [messageStatus, setMessageStatus] = useState('')
    const [message, setMessage] = useState('')

    const handleSubmit = async (e) => {
        e.preventDefault()
        if (!newUsername) {
            setMessage('Ange användarnamn')
            setMessageStatus('message-error')
            return
        } else if (!newName) {
            setMessage('Ange namn')
            setMessageStatus('message-error')
            return
        } else if (!newPassword) {
            setMessage('Ange lösenord')
            setMessageStatus('message-error')
            return
        }

        try {
            const token = sessionStorage.getItem('authToken')
            const username = sessionStorage.getItem('username')

            const response = await axios.post(
                `${webAddress}/api/admin/create-user`,
                { username, token, newUsername, newPassword, newName},
                { withCredentials: true })

            if (response.data.success === true) {
                setMessage('Användare skapad')
                setMessageStatus('message-success')
            } else {
                setMessage('Användare finns redan')
                setMessageStatus('message-error')
            }
        } catch (error) {
            console.error('Error creating user:', error.message)
        }
    }

    if (!isAdmin) {
        window.location.href = "/error401"
        return
    } else {
        return (
            <div className="change">
                <button className="bak-knapp" onClick={() => navigate(-1)}><i className="fa-solid fa-arrow-left" /></button>
                <h1>Skapa ny användare</h1>
                {message && <p className={messageStatus}>{message}</p>}
                <form onSubmit={handleSubmit}>
                    <input 
                        type="text" 
                        placeholder="Användarnamn" 
                        value={newUsername} 
                        onChange={(e) => setNewUsername(e.target.value)} 
                    />
                    <input 
                        type="text"
                        placeholder="Namn"
                        value={newName}
                        onChange={(e) => setNewName(e.target.value)}
                    />
                    <input 
                        type="text"
                        placeholder="Lösenord"
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                    />
                    <button type="submit" className="skicka-knapp">Skicka</button>
                </form>
            </div>
        )
    }
}