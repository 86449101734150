import React from 'react';
import { useNavigate } from "react-router-dom";
import { useAuth } from '../context/AuthContext';

export default function Header() {
    const navigate = useNavigate();
    const { isLoggedIn } = useAuth();

    return (
        <div className="nav-bar">
            <div className="nav-logo" onClick={() => navigate('/')}>
                <img src="/sfk_header_logo_02.png" alt="sfk_logo_02.png" />
            </div>
            <div className="dropdown--position">
                <div className="dropdown">
                    <button className="dropdown--button"><i className="fa fa-navicon" /></button>
                    <div className="dropdown--content">
                        <button className="dropdown-button" onClick={() => navigate('/nyheter')}>Nyheter</button>
                        <button className="dropdown-button" onClick={() => navigate('/flygplan')}>Flygplan</button>
                        {isLoggedIn ? 
                            <button className="dropdown-button" onClick={() => navigate('/profil')}>Profilsida</button>
                            :
                            <button className="dropdown-button" onClick={() => navigate('/login')}>Logga in</button>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}
