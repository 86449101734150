import axios from 'axios'
import { webAddress } from '../context/AuthContext';

export const authorizeUser = async ({ setIsLoggedIn, setIsAdmin, setIsSuperAdmin}) => {
    const token = sessionStorage.getItem('authToken')
    const username = sessionStorage.getItem('username')

    console.log('Attempting to authorize...')
    if (!token || !username) {
        console.error('No token or username found in session storage');
        setIsLoggedIn(false)
        return
    }
    try {
        const response = await axios.post(
            `${webAddress}/api/user`,
            { username, token },
            { 
                withCredentials: true
            })
        console.log('Response received.')

        if (response.data.success === true) {
            console.log('Response ok.')
            setIsLoggedIn(true)
            if (response.data.user.superadmin === 1) {
                setIsSuperAdmin(true)
                setIsAdmin(true)
            } else if (response.data.user.admin === 1) {
                setIsAdmin(true)
                setIsSuperAdmin(false)
            } else {
                setIsAdmin(false)
                setIsSuperAdmin(false)
            }
        } else {
            console.log('Response bad.')
            setIsLoggedIn(false)
        } 
    } catch (error) {
        console.error('Error logging in:', error.message)
        setIsLoggedIn(false)
    }
}